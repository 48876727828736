import { useQueryClient } from '@tanstack/react-query';

import { useActiveTemplate } from '@bloom/library/components/hooks/useActiveTemplate';

import { SharedQueryKeyEnum } from './interface';

function getTemplateColor(activeTemplate: string) {
  switch (activeTemplate) {
    case 'minimalist':
      return '#6D6CFB';
    default:
      return '#ff325a';
  }
}

export const useCustomColor = (color?: string): string => {
  const activeTemplate = useActiveTemplate();

  const queryClient = useQueryClient();
  const systemColor = queryClient.getQueryData<string>([SharedQueryKeyEnum.FREELANCER_BRAND_COLOR]);

  return color || systemColor || getTemplateColor(activeTemplate);
};
