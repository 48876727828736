import { IIconProps } from './types';

const BloomLogo: React.FC<IIconProps> = ({
  className = '',
  color = 'currentColor',
  width = 19,
}) => (
  <svg className={className} height={(+width * 26) / 19} viewBox="0 0 19 26" width={width}>
    <path
      d="M0 0v18.2C0 22.508 3.5 26 7.815 26V7.8C7.815 3.492 4.315 0 0 0M9.769 7.8V26c5.034 0 9.117-4.074 9.117-9.1 0-5.025-4.083-9.1-9.117-9.1"
      fill={color}
      fillRule="evenodd"
    />
  </svg>
);

export default BloomLogo;
