import React from 'react';

import { IIconProps } from './types';

const BloomLogo: React.FC<IIconProps> = (props) => {
  const { width = 115, className = '', color = 'currentColor', ...restProps } = props;

  return (
    <svg
      className={className}
      height={(+width * 26) / 115}
      viewBox="0 0 115 26"
      width={width}
      {...restProps}
    >
      <path
        d="M39.975 21.813A4.875 4.875 0 0 1 35.1 16.94v-.001a4.875 4.875 0 1 1 4.875 4.875m0-13A8.072 8.072 0 0 0 35.1 10.45V5.238a3.25 3.25 0 0 0-3.25-3.25V21.49a3.25 3.25 0 0 0 3.25 3.25v-1.312a8.075 8.075 0 0 0 4.875 1.636 8.125 8.125 0 0 0 0-16.25M50.13 1.988v19.5a3.25 3.25 0 0 0 3.25 3.25v-19.5a3.25 3.25 0 0 0-3.25-3.25M63.455 21.813a4.875 4.875 0 1 1 0-9.749 4.875 4.875 0 0 1 0 9.75m0-13a8.125 8.125 0 1 0 .001 16.25 8.125 8.125 0 0 0 0-16.25M81.655 21.813a4.875 4.875 0 1 1 0-9.749 4.875 4.875 0 0 1 0 9.75m0-13a8.125 8.125 0 1 0 .001 16.25 8.125 8.125 0 0 0 0-16.25M107.98 8.813a6.481 6.481 0 0 0-4.875 2.21 6.479 6.479 0 0 0-8.78-.897 3.233 3.233 0 0 0-2.594-1.313v13a3.25 3.25 0 0 0 3.25 3.25v-9.75a3.25 3.25 0 1 1 6.5 0v6.5a3.25 3.25 0 0 0 3.25 3.25v-9.75a3.25 3.25 0 1 1 6.5 0v6.5a3.25 3.25 0 0 0 3.25 3.25v-9.75a6.5 6.5 0 0 0-6.5-6.5"
        fill={color}
      />
      <path d="M0 0v18.2A7.8 7.8 0 0 0 7.8 26V7.8A7.8 7.8 0 0 0 0 0" fill={color} />
      <path d="M9.75 7.8V26a9.1 9.1 0 0 0 0-18.2" fill={color} />
    </svg>
  );
};

export default BloomLogo;
