'use client';
import React, { useEffect, useState } from 'react';

import { twMerge } from 'tailwind-merge';

import { ImageSizeEnum } from '@bloom/library/components/Gallery/types';
import { useImageFallback } from '@bloom/library/components/hooks/useImageFallback';
import BloomLogoIcon from '@bloom/library/components/Icon/BloomLogo';
import BloomLogoShortIcon from '@bloom/library/components/Icon/BloomLogoShort';

interface IProps {
  className?: string;
  imageClassName?: string;
  isShort?: boolean;
  maxSize?: {
    height?: string;
    width?: string;
  };
  removeBloomBranding?: boolean;
  size?: keyof typeof ImageSizeEnum;
  src?: string;
}

let isHydrating = true;

const CompanyLogo: React.FC<IProps> = (props) => {
  const {
    className = '',
    imageClassName = '',
    isShort = false,
    maxSize = {},
    removeBloomBranding = true,
    size = 'SM',
    src = '',
  } = props;
  const [isHydrated, setIsHydrated] = useState(!isHydrating);

  useEffect(() => {
    setIsHydrated(true);
    isHydrating = false;
  }, []);

  const imgStyle = {
    maxHeight: (maxSize && maxSize.height) || '',
    maxWidth: (maxSize && maxSize.width) || '',
  };

  const { src: source } = useImageFallback(src, size);

  if ((!source && removeBloomBranding) || !isHydrated) {
    return <div />;
  }

  return (
    <div className={twMerge('inline-block dark:text-white', className)}>
      {source ? (
        <img
          className={twMerge('block max-h-12 max-w-52', imageClassName)}
          data-testid="company-logo"
          src={source}
          style={imgStyle}
        />
      ) : isShort ? (
        <BloomLogoShortIcon className="block" data-testid="smallsize-bloom-logo" width={26} />
      ) : (
        <BloomLogoIcon className="block" data-testid="fullsize-bloom-logo" width={115} />
      )}
    </div>
  );
};

export default CompanyLogo;
