import { ImageSizeEnum, ImageSizePrefixEnum } from '@bloom/library/components/Gallery/types';

export function isImage(file: File) {
  return !!file && ['image/jpeg', 'image/jpg', 'image/png'].includes(file.type);
}

export const getImageDimensions = (file: File): Promise<{ height: number; width: number }> =>
  new Promise((resolve) => {
    const url = URL.createObjectURL(file);
    const img = new Image();

    img.onload = () => {
      resolve({
        height: img.height,
        width: img.width,
      });
    };

    img.src = url;
  });

export function getSizeSuffix(width: number) {
  if (width > 1024) {
    return 'XL';
  }
  if (width > 800) {
    return 'LG';
  }
  if (width > 400) {
    return 'MD';
  }
  if (width > 150) {
    // Load md image even on small screens,
    // because of retina.
    return 'MD';
  }

  return 'XS';
}

export function getImageURLWithExtension(src = '') {
  const urlAfterLastSlash = src.match(/[^/]+$/);
  if (urlAfterLastSlash) {
    return urlAfterLastSlash[0];
  } else {
    return '';
  }
}

export function getImageUrl(src: string, size: keyof typeof ImageSizeEnum) {
  if (src) {
    if (/imgix.net/.test(src)) {
      return `${src}?width=${ImageSizeEnum[size]}`;
    }

    if (src.includes(process.env.BLOOM_CDN)) {
      return src.replace(/(\.[\w\d?=_-]+)$/i, `-${ImageSizePrefixEnum[size]}$1`);
    }

    return src;
  }

  return '';
}
