import React from 'react';

import { useCustomColor } from '@bloom/library/components/hooks/useCustomColor';

import { PrimaryButton, PrimaryButtonVariant } from '.';

const BrandColorButton = React.forwardRef<
  HTMLButtonElement,
  React.ComponentProps<typeof PrimaryButton>
>((props, forwardedRef) => {
  const customColor = useCustomColor();

  return (
    <PrimaryButton
      {...props}
      color={customColor}
      ref={forwardedRef}
      variant={PrimaryButtonVariant.CUSTOM}
    />
  );
});

export { BrandColorButton };
