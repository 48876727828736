'use client';
import { useQueryClient } from '@tanstack/react-query';

import { SharedQueryKeyEnum } from './interface';
import { usePublicAccountByCustomUrl } from './usePublicAccount';

type WebsiteTemplate = 'vertical-split' | 'nordic-air' | 'minimalist' | 'default' | (string & {});

export function useActiveTemplate(template?: WebsiteTemplate) {
  const queryClient = useQueryClient();

  const { publicAccount } = usePublicAccountByCustomUrl({ enabled: !template });

  const activeTemplate = queryClient.getQueryData<string>([SharedQueryKeyEnum.ACTIVE_TEMPLATE]);

  const searchParams = new URLSearchParams(
    typeof window === 'undefined' ? '' : window.location.search
  );
  const templateDemo = searchParams.get('template-demo');

  const selectedTemplate =
    templateDemo || template || activeTemplate || publicAccount?.activeTemplate || 'vertical-split';

  return selectedTemplate === 'default' ? 'vertical-split' : selectedTemplate;
}
