'use client';
import React from 'react';

import Link from 'next/link';

import { Input } from '@bloom/ui/components/Input';
import { BrandColorButton } from '@bloom/ui/components/PrimaryButton/BrandColorButton';

import CompanyLogo from '@bloom/library/components/CompanyLogo';
import { useInfoMessage } from '@bloom/library/components/FlashMessageV2/info-message-context';
import { useAuthentication } from '@bloom/library/components/hooks/useAuthentication';
import { AsyncStatusEnum } from '@bloom/library/components/hooks/useFetch';
import { useLoaderPublicAccount } from '@bloom/library/components/hooks/usePublicAccount';
import { HMega } from '@bloom/library/components/Typography/HMega';

export const LoginForm: React.FC = () => {
  const publicAccount = useLoaderPublicAccount();
  const freelanceLogo = publicAccount?.logo || '';

  const { isLoginIn, login } = useAuthentication();

  const { showErrorMessageFromResponse } = useInfoMessage();

  function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const email = formData.get('email') as string;
    const password = formData.get('password') as string;
    // After login either user is redirected to Portal,
    // or page is reloaded, so no need in closing the modal window.

    login({ email, password }).then((action) => {
      if (action.status === AsyncStatusEnum.ERROR) {
        showErrorMessageFromResponse(action);
      }
    });
  }

  return (
    <div className="flex min-h-screen flex-col items-center justify-center px-6 pt-12 pb-30 sm:pt-20">
      <CompanyLogo src={freelanceLogo} />

      <HMega className="mt-24" data-testid="login-page-header">
        Log in to your portal
      </HMega>
      <p className="mt-3 text-center">Enter your email and password.</p>

      <form
        className="mt-12 flex w-full max-w-sm flex-col gap-6"
        method="post"
        onSubmit={handleSubmit}
      >
        <Input
          autoFocus
          data-testid="login-form-email-input"
          label="Email"
          name="email"
          placeholder="Email"
          required
          type="email"
        />

        <Input
          data-testid="login-form-password-input"
          label="Password"
          name="password"
          placeholder="Enter Password"
          required
          type="password"
        />

        <Link className="text-grey self-end hover:underline" href="/password-reset">
          Forgot password?
        </Link>

        <BrandColorButton
          className="mx-auto mt-6"
          data-testid="submit-button"
          loading={isLoginIn}
          type="submit"
        >
          Login
        </BrandColorButton>

        <nav className="text-grey mt-6 text-center">
          No account?{' '}
          <Link className="underline hover:no-underline" data-testid="signup-link" href="/signup">
            Sign Up
          </Link>
        </nav>
      </form>
    </div>
  );
};
