export enum GalleryGridTypeEnum {
  ROWS = 'ROWS',
  COLUMNS = 'COLUMNS',
  SQUARES = 'SQUARES',
  SLIDESHOW = 'SLIDESHOW',
  SINGLE_COLUMN = 'SINGLE_COLUMN',
}

export enum AlbumSortByEnum {
  CUSTOM = 'CUSTOM',
}

export enum ImageSizeEnum {
  XS = '150', // 150x150
  SM = '400', // 400x300
  MD = '800', // 800x600
  LG = '1024', // 1024x768
  XL = '1920', // 1920x1080
}

export enum ImageSizePrefixEnum {
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
}
