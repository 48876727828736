'use client';
import { useMemo } from 'react';

import { UseQueryOptions, useQuery, useQueryClient } from '@tanstack/react-query';

import { PublicAccountResponse } from '@bloom/codegen/models/PublicAccountResponse';

import { getFreelancerSubdomainFromHost } from '@bloom/library/utils/browser';

import { SharedQueryKeyEnum } from './interface';
import { AsyncStatusEnum, useFetch } from './useFetch';

export function useLoaderPublicAccount() {
  const queryClient = useQueryClient();
  const publicAccount = queryClient.getQueryData<PublicAccountResponse>([
    SharedQueryKeyEnum.LOADER_PUBLIC_ACCOUNT,
  ]);

  return publicAccount;
}

export function usePublicAccount(
  accountId = '',
  queryOptions: Partial<UseQueryOptions<PublicAccountResponse | null>> = {}
) {
  const { get } = useFetch();

  const queryClient = useQueryClient();

  async function fetchAccount() {
    const response = await get<{ account: PublicAccountResponse }>(
      `/api/public-accounts/${accountId}`
    );

    if (response.status === AsyncStatusEnum.SUCCESS) {
      queryClient.setQueryData(
        [SharedQueryKeyEnum.PUBLIC_ACCOUNT, response.data.account.settings.customUrl],
        response.data.account
      );
      queryClient.setQueryData(
        [SharedQueryKeyEnum.PUBLIC_ACCOUNT, response.data.account.id],
        response.data.account
      );
      return response.data.account;
    }

    return null;
  }

  const { data, isFetching } = useQuery({
    enabled: !!accountId,
    queryFn: fetchAccount,
    queryKey: [SharedQueryKeyEnum.PUBLIC_ACCOUNT, accountId],
    ...queryOptions,
  });

  return useMemo(() => ({ account: data, isFetching }), [data, isFetching]);
}

export function usePublicAccountByCustomUrl(
  queryOptions: Partial<UseQueryOptions<PublicAccountResponse | null>> = {}
) {
  const customUrl = getFreelancerSubdomainFromHost();

  const result = usePublicAccount(customUrl, queryOptions);

  return useMemo(() => {
    const { account, ...restResult } = result;
    return { publicAccount: account, ...restResult };
  }, [result]);
}
