'use client';
import { useEffect, useMemo, useRef, useState } from 'react';

import { ImageSizeEnum } from '@bloom/library/components/Gallery/types';
import { AsyncStatusEnum } from '@bloom/library/components/hooks/useFetch';
import { getImageUrl } from '@bloom/library/utils/image';

export function useImageFallback(source: string, size: keyof typeof ImageSizeEnum) {
  const [src, setState] = useState(getImageUrl(source, size));
  const [status, setStatus] = useState(AsyncStatusEnum.IDLE);

  useEffect(() => {
    if (source) {
      setState(getImageUrl(source, size));
    }
  }, [source, size]);

  const fallbackRef = useRef(false);

  useEffect(() => {
    function handleLoad() {
      setStatus(AsyncStatusEnum.SUCCESS);
    }

    function handleLoadError() {
      setStatus(AsyncStatusEnum.ERROR);

      if (fallbackRef.current) {
        return;
      }

      fallbackRef.current = true;
      const filename = source.split('/').pop();
      setState(`${process.env.BLOOM_FILE}/file/${filename}`);
    }

    if (!src) {
      return;
    }

    let img = new Image();
    img.src = src;

    img.addEventListener('error', handleLoadError);
    img.addEventListener('load', handleLoad);

    return () => {
      fallbackRef.current = false;
      img.removeEventListener('error', handleLoadError);
      img.removeEventListener('load', handleLoad);

      img = null;
    };
  }, [source, src]);

  return useMemo(() => ({ src, status }), [src, status]);
}
